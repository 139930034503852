<mat-form-field cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <input
    matInput
    type="time"
    placeholder="HH:MM"
    (change)="parseTime()"
    [formControl]="timeFormControl.controls.time"
    [errorStateMatcher]="matcher"
  />
  <button
    matSuffix
    mat-icon-button
    matTooltip="Select time"
    type="button"
    (click)="togglePanel($event)"
  >
    <mat-icon svgIcon="schedule" />
  </button>
</mat-form-field>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen()"
  [cdkConnectedOverlayFlexibleDimensions]="false"
  [cdkConnectedOverlayLockPosition]="true"
  [cdkConnectedOverlayViewportMargin]="8"
  [cdkConnectedOverlayTransformOriginOn]="'ath-time-picker-details'"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ]"
  (overlayOutsideClick)="timePickerDetails().close()"
>
  <ath-time-picker-details
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    [(hour)]="hour"
    [(minute)]="minute"
    (closed)="closePanel()"
  />
</ng-template>
