import { A11yModule } from "@angular/cdk/a11y";
import { TimePickerDetailsComponent } from "./time-picker-details/time-picker-details.component";
import {
  AfterViewInit,
  Component,
  computed,
  DestroyRef,
  input,
  output,
  signal,
  viewChild,
} from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatInputModule } from "@angular/material/input";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

export interface TimePickerChangeEvent {
  hour: number;
  minute: number;
}

class TimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "ath-time-picker",
  standalone: true,
  imports: [
    A11yModule,
    TimePickerDetailsComponent,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    OverlayModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./time-picker.component.html",
  styleUrl: "./time-picker.component.scss",
})
export class TimePickerComponent implements AfterViewInit {
  private initialHour: number | undefined;
  private initialMinute: number | undefined;
  private time = computed(() => {
    return `${this.hour().toString().padStart(2, "0")}:${this.minute().toString().padStart(2, "0")}`;
  });

  isOpen = signal(false);

  defaultValue = input<string>();

  hour = signal(0);
  minute = signal(0);

  timeFormControl = this.fb.group({
    time: this.fb.control("", [
      Validators.required,
      Validators.pattern(/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/),
    ]),
  });

  matcher = new TimeErrorStateMatcher();
  timePickerDetails = viewChild.required<TimePickerDetailsComponent>(TimePickerDetailsComponent);

  timeChange = output<TimePickerChangeEvent>();

  constructor(
    private fb: NonNullableFormBuilder,
    private destroyRef: DestroyRef
  ) {
    const changeSub = this.timeFormControl.controls.time.valueChanges.subscribe((time) => {
      this.timeChange.emit({
        hour: parseInt(time.split(":")[0]),
        minute: parseInt(time.split(":")[1]),
      });
    });

    this.destroyRef.onDestroy(() => {
      changeSub.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    if (this.defaultValue() !== undefined) {
      this.parseTime(this.defaultValue());
    }

    this.timeFormControl.controls.time.setValue(this.time());
  }

  togglePanel(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    if (this.isOpen()) {
      this.timePickerDetails().close();
    } else {
      this.isOpen.set(true);

      this.initialHour = this.hour();
      this.initialMinute = this.minute();
    }
  }

  closePanel(): void {
    this.isOpen.set(false);

    if (this.initialHour !== this.hour() || this.initialMinute !== this.minute()) {
      this.timeFormControl.controls.time.setValue(this.time());
    }
  }

  parseTime(timeString: string | undefined = undefined): void {
    if (timeString) {
      const time = timeString.split(":");
      this.timeFormControl.controls.time.setValue(timeString);
      this.hour.set(parseInt(time[0]));
      this.minute.set(parseInt(time[1]));
      return;
    }

    if (this.timeFormControl.controls.time.valid) {
      const time = this.timeFormControl.controls.time.value.split(":");
      this.hour.set(parseInt(time[0]));
      this.minute.set(parseInt(time[1]));
    }
  }

  reset(): void {
    this.timeFormControl.reset({ time: this.defaultValue() ?? "00:00" });
  }
}
