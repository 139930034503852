import { Component } from "@angular/core";
import { enterLeaveFadeInOutAnimation } from "@shared/animations/";

@Component({
  selector: "ath-loading-ripple",
  standalone: true,
  imports: [],
  templateUrl: "./loading-ripple.component.html",
  styleUrl: "./loading-ripple.component.scss",
  host: {
    "[@enterLeaveFadeInOutAnimation]": "true",
  },
  animations: [enterLeaveFadeInOutAnimation],
})
export class LoadingRippleComponent {}
