import { DropdownMultiselectItem } from "@shared/models";
import { IconComponent, RowComponent } from "@shared/components";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, output, signal } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: "ath-dropdown-multiselect",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    IconComponent,
    RowComponent,
  ],
  templateUrl: "./dropdown-multiselect.component.html",
  styleUrl: "./dropdown-multiselect.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMultiselectComponent {
  title = input<string | undefined>();
  placeholder = input<string>("");
  items = input.required<DropdownMultiselectItem[]>();
  selectionUpdated = output<DropdownMultiselectItem[]>();
  selectedItems = new FormControl<DropdownMultiselectItem[]>([]);

  private dropdownOpen = signal<boolean>(false);

  get selectedItemsCount(): number | undefined {
    return this.selectedItems.value?.length;
  }

  get multipleSelectionText(): string {
    const selection = this.selectedItems.value?.length;
    return `(+${(selection || 0) - 1} ${selection === 2 ? "other" : "others"})`;
  }

  get firstSelectedItemName(): string {
    return this.selectedItems.value?.[0]?.value || "";
  }

  onDropdownToggled(): void {
    this.dropdownOpen.update((current) => !current);
  }

  onSelectionChange(selected: DropdownMultiselectItem[]): void {
    this.selectionUpdated.emit(selected);
  }

  clearSelection(): void {
    this.selectedItems.setValue([]);
    this.selectionUpdated.emit([]);
  }
}
