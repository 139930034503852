import { animate, style, transition, trigger } from "@angular/animations";

const timing = "400ms cubic-bezier(0.3, 0.9, 0.2, 0.9)";

export const navbarTransitionAnimation = trigger("navbarTransitionAnimation", [
  transition("void <=> *", []),
  transition("* <=> *", [style({ width: "{{startWidth}}px" }), animate(timing)], {
    params: { startWidth: 0 },
  }),
]);
