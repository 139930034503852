import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { RowOptions } from "@shared/models";

@Component({
  selector: "ath-row-layout",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./row-layout.component.html",
  styleUrl: "./row-layout.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowLayoutComponent {
  private defaults = {
    wrap: "wrap",
    justify: "space-between",
    align: "stretch",
  };

  @Input() options?: RowOptions;

  @HostBinding("style.flex-wrap")
  get wrapStyle(): string {
    return this.options?.wrap ?? this.defaults.wrap;
  }

  @HostBinding("style.justify-content")
  get justifyStyle(): string {
    return this.options?.justify ?? this.defaults.justify;
  }

  @HostBinding("style.align-items")
  get alignStyle(): string {
    return this.options?.align ?? this.defaults.align;
  }
}
