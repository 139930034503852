import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { ColumnOptions } from "@shared/models";

@Component({
  selector: "ath-column",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./column.component.html",
  styleUrl: "./column.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent {
  private defaults: ColumnOptions = {
    gap: "16px",
    justify: "flex-start",
    align: "flex-start",
    padding: "16px",
    width: "fit-content",
  };

  @Input() options?: ColumnOptions;

  @HostBinding("style.gap")
  get gapStyle(): string {
    return this.options?.gap ?? this.defaults.gap!;
  }

  @HostBinding("style.justify-content")
  get justifyStyle(): string {
    return this.options?.justify ?? this.defaults.justify!;
  }

  @HostBinding("style.align-items")
  get alignStyle(): string {
    return this.options?.align ?? this.defaults.align!;
  }

  @HostBinding("style.padding")
  get paddingStyle(): string {
    return this.options?.padding ?? this.defaults.padding!;
  }

  @HostBinding("style.width") get widthStyle(): string {
    return this.options?.width ?? this.defaults.width!;
  }
}
