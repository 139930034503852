import { Component, output, input } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "ath-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    ReactiveFormsModule,
    IconComponent,
  ],
})
export class SharedDatePickerComponent {
  label = input("Date");
  control = input(new FormControl<Date | null>(null));
  maxDate = input<Date | null>(null);
  minDate = input<Date | null>(null);
  dateChange = output<Date | null>();

  onDateChange(event: MatDatepickerInputEvent<Date | null>): void {
    const date = event.value;
    this.control().setValue(date);
    this.dateChange.emit(date);
  }
}
