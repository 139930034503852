<span class="title">ENTER TIME</span>
<div class="fields">
  <div>
    <input
      matInput
      athOnlyNumber
      class="time-input"
      type="text"
      [formControl]="timeForm.controls.hour"
      (wheel)="adjustTime($event, 'hour')"
      (focus)="selectField($event)"
      (click)="selectField($event)"
      (input)="updateTimeValues()"
    />
    <span>Hour</span>
  </div>
  <span class="seperator">:</span>
  <div>
    <input
      matInput
      athOnlyNumber
      class="time-input"
      type="text"
      [formControl]="timeForm.controls.minute"
      (wheel)="adjustTime($event, 'minute')"
      (focus)="selectField($event)"
      (click)="selectField($event)"
      (input)="updateTimeValues()"
    />
    <span>Minute</span>
  </div>
</div>
<button mat-stroked-button (click)="setTimeNow()">NOW</button>
<button
  mat-raised-button
  class="close-button"
  [class.cdk-visually-hidden]="closeButtonHidden()"
  (focus)="setCloseButtonVisibility(false)"
  (blur)="setCloseButtonVisibility(true)"
  (keypress)="close()"
>
  Close time picker
</button>
