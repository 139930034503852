<button
  mat-icon-button
  [matTooltip]="tooltip()"
  [disabled]="disabled()"
  title=""
  aria-label="TODO"
  type="button"
  (click)="onClick($event)"
>
  <mat-icon [svgIcon]="icon()" [class]="scale()" />
</button>
