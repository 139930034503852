import { animate, animation, style, transition, trigger, useAnimation } from "@angular/animations";

const enterTiming = "0.4s ease-in-out";
const leaveTiming = "0.2s ease-in-out";

export const transitionAnimation = animation([
  style({
    opacity: "{{ initialOpacity }}",
  }),
  animate("{{ timing }}", style({ opacity: "{{ targetOpacity }}" })),
]);

export const enterLeaveFadeInOutAnimation = trigger("enterLeaveFadeInOutAnimation", [
  transition(":enter", [
    useAnimation(transitionAnimation, {
      params: { initialOpacity: 0, timing: enterTiming, targetOpacity: 1 },
    }),
  ]),
  transition(":leave", [
    useAnimation(transitionAnimation, {
      params: { initialOpacity: 1, timing: leaveTiming, targetOpacity: 0 },
    }),
  ]),
]);
