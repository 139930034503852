<mat-form-field>
  <mat-label>{{ label() }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    (dateChange)="onDateChange($event)"
    [formControl]="control()"
    [max]="maxDate()"
    [min]="minDate()"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <ath-icon matDatepickerToggleIcon icon="calendar-month"></ath-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
