import { animate, state, style, transition, trigger } from "@angular/animations";

export const panelTransitionAnimation = trigger("panelTransitionAnimation", [
  state(
    "hidden",
    style({
      opacity: 0,
      transform: "scale(1, 0.8)",
    })
  ),
  state(
    "visible",
    style({
      opacity: 1,
      transform: "scale(1, 1)",
    })
  ),
  transition("hidden <=> visible", animate("120ms cubic-bezier(0, 0, 0.2, 1)")),
]);
